const expressMap = [
	{ name: 'EMS', num: 'ems', cnt: 6078269, type: '国际邮政' },
	{ name: '顺丰速运', num: 'shunfeng', cnt: 15836540, type: '国际运输商' },
	{ name: '圆通速递', num: 'yuantong', cnt: 41660457, type: '国内运输商' },
	{ name: '中通快递', num: 'zhongtong', cnt: 22182799, type: '国内运输商' },
	{ name: '申通快递', num: 'shentong', cnt: 15131498, type: '国内运输商' },
	{ name: '韵达快递', num: 'yunda', cnt: 31842276, type: '国内运输商' },
	{ name: '百世快递', num: 'huitongkuaidi', cnt: 13805377, type: '国内运输商' },
	{ name: '京东物流', num: 'jd', cnt: 7829277, type: '国内运输商' },
	{ name: '德邦快递', num: 'debangkuaidi', cnt: 662880, type: '国内运输商' }
	/* { name: '邮政快递包裹', num: 'youzhengguonei', cnt: 13403075, type: '国际邮政' },
	{ name: '极兔速递', num: 'jtexpress', cnt: 3699993, type: '国内运输商' },
	{ name: '邮政标准快递', num: 'youzhengbk', cnt: 1897697, type: '国际邮政' },
	{ name: '德邦', num: 'debangwuliu', cnt: 1141764, type: '国内运输商' },
	{ name: '优速快递', num: 'youshuwuliu', cnt: 347450, type: '国内运输商' },
	{ name: '宅急送', num: 'zhaijisong', cnt: 338956, type: '国内运输商' },
	{ name: '圆通快运', num: 'yuantongkuaiyun', cnt: 266033, type: '国内运输商' },
	{ name: '百世快运', num: 'baishiwuliu', cnt: 263703, type: '国内运输商' },
	{ name: '众邮快递', num: 'zhongyouex', cnt: 143267, type: '国内运输商' },
	{ name: '中通快运', num: 'zhongtongkuaiyun', cnt: 116475, type: '国内运输商' },
	{ name: 'UPS', num: 'ups', cnt: 100100, type: '国际运输商' },
	{ name: '安能快运', num: 'annengwuliu', cnt: 99874, type: '国内运输商' },
	{ name: '中通国际', num: 'zhongtongguoji', cnt: 98600, type: '国际运输商' },
	{ name: '顺丰快运', num: 'shunfengkuaiyun', cnt: 98250, type: '国内运输商' },
	{ name: '韵达快运', num: 'yundakuaiyun', cnt: 89254, type: '国内运输商' },
	{ name: '丹鸟', num: 'danniao', cnt: 83094, type: '国内运输商' },
	{ name: 'DHL-中国件', num: 'dhl', cnt: 60868, type: '国际运输商' },
	{ name: 'FedEx-国际件', num: 'fedex', cnt: 58533, type: '国际运输商' },
	{ name: 'UPS-全球件', num: 'upsen', cnt: 57980, type: '国际运输商' },
	{ name: '特急送', num: 'lntjs', cnt: 55074, type: '国内运输商' },
	{ name: 'D速快递', num: 'dsukuaidi', cnt: 52370, type: '国际运输商' },
	{ name: 'DHL-全球件', num: 'dhlen', cnt: 48301, type: '国际运输商' },
	{ name: '天天快递', num: 'tiantian', cnt: 42266, type: '国内运输商' },
	{ name: '跨越速运', num: 'kuayue', cnt: 34889, type: '国内运输商' },
	{ name: '安得物流', num: 'annto', cnt: 32914, type: '国内运输商' },
	{ name: 'USPS', num: 'usps', cnt: 32209, type: '国际邮政' },
	{ name: '国际包裹', num: 'youzhengguoji', cnt: 31735, type: '国际运输商' },
	{ name: '壹米滴答', num: 'yimidida', cnt: 30031, type: '国内运输商' },
	{ name: '递四方', num: 'disifang', cnt: 28867, type: '国内运输商' },
	{ name: '京广速递', num: 'jinguangsudikuaijian', cnt: 27466, type: '国内运输商' },
	{ name: '加运美', num: 'jiayunmeiwuliu', cnt: 27294, type: '国内运输商' },
	{ name: '九曳供应链', num: 'jiuyescm', cnt: 25203, type: '国内运输商' },
	{ name: 'YUN TRACK', num: 'yuntrack', cnt: 23377, type: '国际运输商' },
	{ name: '速尔快递', num: 'suer', cnt: 20315, type: '国内运输商' },
	{ name: '苏宁物流', num: 'suning', cnt: 20212, type: '国内运输商' },
	{ name: '信丰物流', num: 'xinfengwuliu', cnt: 19606, type: '国内运输商' },
	{ name: '快捷速递', num: 'kuaijiesudi', cnt: 18685, type: '国内运输商' },
	{ name: 'EMS-国际件', num: 'emsguoji', cnt: 18255, type: '国际邮政' },
	{ name: '同城快寄', num: 'shpost', cnt: 18198, type: '国内运输商' },
	{ name: '顺心捷达', num: 'sxjdfreight', cnt: 16858, type: '国内运输商' },
	{ name: 'EMS包裹', num: 'emsbg', cnt: 16583, type: '国际邮政' },
	{ name: '广东邮政', num: 'guangdongyouzhengwuliu', cnt: 16115, type: '国际邮政' },
	{ name: 'EWE全球快递', num: 'ewe', cnt: 16054, type: '国际运输商' },
	{ name: '美快国际物流', num: 'meiquick', cnt: 15471, type: '国际运输商' },
	{ name: '联昊通', num: 'lianhaowuliu', cnt: 15257, type: '国内运输商' },
	{ name: '速腾快递', num: 'suteng', cnt: 14650, type: '国内运输商' },
	{ name: 'TNT', num: 'tnt', cnt: 14433, type: '国际运输商' },
	{ name: '国通快递', num: 'guotongkuaidi', cnt: 14020, type: '国内运输商' },
	{ name: 'GLS', num: 'gls', cnt: 13911, type: '国际运输商' },
	{ name: 'Fedex-国际件-中文', num: 'fedexcn', cnt: 13859, type: '国际运输商' },
	{ name: '日日顺物流', num: 'rrs', cnt: 13815, type: '国内运输商' },
	{ name: '微特派', num: 'weitepai', cnt: 13238, type: '国内运输商' },
	{ name: '威盛快递', num: 'wherexpess', cnt: 12772, type: '国内运输商' },
	{ name: '全峰快递', num: 'quanfengkuaidi', cnt: 12425, type: '国内运输商' },
	{ name: '汇森速运', num: 'huisenky', cnt: 11710, type: '国内运输商' },
	{ name: '联合快递', num: 'gslhkd', cnt: 11320, type: '国内运输商' },
	{ name: '芝麻开门', num: 'zhimakaimen', cnt: 11227, type: '国内运输商' },
	{ name: '申通国际', num: 'stosolution', cnt: 11210, type: '国际运输商' },
	{ name: '速必达', num: 'subida', cnt: 10892, type: '国内运输商' },
	{ name: '宇鑫物流', num: 'yuxinwuliu', cnt: 10543, type: '国内运输商' },
	{ name: '日本（Japan Post）', num: 'japanposten', cnt: 9661, type: '国际邮政' },
	{ name: 'CJ物流', num: 'doortodoor', cnt: 8830, type: '国际运输商' },
	{ name: '叁虎物流', num: 'sanhuwuliu', cnt: 8238, type: '国内运输商' },
	{ name: 'EMS物流', num: 'emswuliu', cnt: 7641, type: '国内运输商' },
	{ name: '三态速递', num: 'santaisudi', cnt: 7532, type: '国内运输商' },
	{ name: '燕文物流', num: 'yw56', cnt: 7248, type: '国内运输商' },
	{ name: '中远e环球', num: 'cosco', cnt: 7127, type: '国际运输商' },
	{ name: '如风达', num: 'rufengda', cnt: 6956, type: '国内运输商' },
	{ name: '新顺丰（NSF）', num: 'nsf', cnt: 6610, type: '国际运输商' },
	{ name: '平安达腾飞', num: 'pingandatengfei', cnt: 6416, type: '国内运输商' },
	{ name: '中邮速递', num: 'wondersyd', cnt: 6363, type: '国内运输商' },
	{ name: '联邦快递', num: 'lianbangkuaidi', cnt: 6256, type: '国内运输商' },
	{ name: '联邦快递-英文', num: 'lianbangkuaidien', cnt: 6210, type: '国内运输商' },
	{ name: '优邦速运', num: 'ubonex', cnt: 5732, type: '国内运输商' },
	{ name: 'TNT-全球件', num: 'tnten', cnt: 5423, type: '国际运输商' },
	{ name: '中邮物流', num: 'zhongyouwuliu', cnt: 5385, type: '国内运输商' },
	{ name: '斑马物流', num: 'banma', cnt: 5366, type: '国内运输商' },
	{ name: '盛辉物流', num: 'shenghuiwuliu', cnt: 5296, type: '国内运输商' },
	{ name: '天马迅达', num: 'tianma', cnt: 5170, type: '国内运输商' },
	{ name: '增益速递', num: 'zengyisudi', cnt: 4716, type: '国内运输商' },
	{ name: '龙邦速递', num: 'longbanwuliu', cnt: 4554, type: '国内运输商' },
	{ name: '程光快递', num: 'flyway', cnt: 4548, type: '国内运输商' },
	{ name: '大马鹿', num: 'idamalu', cnt: 4487, type: '国内运输商' },
	{ name: '澳洲飞跃物流', num: 'rlgaus', cnt: 4402, type: '国际运输商' },
	{ name: 'Xlobo贝海国际', num: 'xlobo', cnt: 4344, type: '国际运输商' },
	{ name: '汇通天下物流', num: 'httx56', cnt: 4186, type: '国内运输商' },
	{ name: '鑫正一快递', num: 'zhengyikuaidi', cnt: 4100, type: '国内运输商' },
	{ name: 'EMS-国际件-英文', num: 'emsinten', cnt: 4076, type: '国际邮政' },
	{ name: 'DPD', num: 'dpd', cnt: 3889, type: '国际运输商' },
	{ name: '天地华宇', num: 'tiandihuayu', cnt: 3865, type: '国内运输商' },
	{ name: '疯狂快递', num: 'crazyexpress', cnt: 3834, type: '国内运输商' } */
];
export default expressMap;