<template>
	<div>
		<el-form :inline="true" @submit.native.prevent @keyup.enter.native.prevent="search">
			<el-form-item label="订单编号：">
				<el-input v-model="filters.orderId" placeholder="订单编号" clearable></el-input>
			</el-form-item>
			<el-form-item label="药品名称：">
				<el-input v-model="filters.medicineName" placeholder="药品名称" clearable></el-input>
			</el-form-item>
			<el-form-item label="下单时间：">
				<el-date-picker v-model="filters.timeRange" type="daterange" align="right" unlink-panels
					range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
				</el-date-picker>
			</el-form-item>
			<el-form-item label="客户信息：">
				<el-input style="width: 150px" v-model="filters.patientName" placeholder="客户姓名" clearable>
				</el-input>
			</el-form-item>
			<el-form-item label="客户电话：">
				<el-input style="width: 150px" v-model="filters.mobilePhone" placeholder="客户电话" clearable></el-input>
			</el-form-item>
			<!-- <el-form-item label="下单账号：">
				<el-input v-model="filters.patientName" placeholder="下单账号" clearable></el-input>
			</el-form-item>
			<el-form-item label="物流单号：">
				<el-input v-model="filters.patientName" placeholder="物流单号" clearable></el-input>
			</el-form-item> -->
			<el-form-item>
				<el-button type="primary" @click="search">查询</el-button>
			</el-form-item>
		</el-form>
		<el-tabs v-model="filters.status" @tab-click="handleClickTab">
			<el-tab-pane v-for="item in orderStatuss" :key="item.label" :label="item.label" :name="item.value">
				<el-table :data="dataList" border style="width: 100%;">
					<el-table-column type="index" width="50" align="center" label="序号"> </el-table-column>
					<el-table-column prop="orderId" header-align="center" align="left" label="订单号" width="200px"></el-table-column>
					<el-table-column prop="createdAt" header-align="center" align="left" label="下单时间"></el-table-column>
					<!-- <el-table-column prop="rpArray" header-align="center" align="left" label="数量x剂数">
						<template slot-scope="scope">
							<div>
								<div v-for="(r, index) in scope.row.rpArray" :key="index">
									<div style="display: flex; justify-content: space-between;">
										<span>{{r.name}}</span><span style="margin-left: 10px;">{{r.price}}{{r.unit}} x
											{{r.count}}</span></div>
								</div>
							</div>
						</template>
					</el-table-column> -->
					<el-table-column prop="patientName" header-align="center" align="center" label="患者信息">
						<template slot-scope="scope">
							<div>
								<div>{{scope.row.patientName}}</div>
								<div>{{scope.row.showFullPhone ? scope.row.mobilePhone : scope.row.mobilePhoneHide}}
								</div>
								<div class="click-to-show" @click="clickToShow(scope.row)">
									<a>{{scope.row.showFullPhone ? '点击隐藏' : '点击查看'}}</a>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="doctorName" header-align="center" align="center" label="开方医生"></el-table-column>
					<el-table-column prop="pharmacyName" header-align="center" align="center" label="药房名称"></el-table-column>
					<el-table-column prop="dosageForm" header-align="center" align="center" label="处方类型"></el-table-column>
					<el-table-column prop="logisticsOrderNo" header-align="center" align="center" label="物流信息"></el-table-column>
					<el-table-column prop="status" header-align="center" align="center" label="订单状态">
						<template slot-scope="scope">
							{{
								scope.row.status === 1 ? '待支付' :
								scope.row.status === 2 ? '待收货' :
								scope.row.status === 3 ? '已完成' :
								scope.row.status === 4 ? '已取消' :
								scope.row.status === 5 ? '待发货' :
								scope.row.status === 6 ? '已退款' : ''
							}}
						</template>
					</el-table-column>
					<el-table-column prop="totalFee" header-align="center" align="center" label="实付金额（元）">
						<template slot-scope="scope">
							{{scope.row.totalFee}}
						</template>
					</el-table-column>
					<el-table-column header-align="center" align="center" label="操作">
						<template slot-scope="scope">
							<el-button type="text" size="small" @click="detailHandle(scope.row.id)">订单详情</el-button>
							<el-button
								v-if="scope.row.status === 5"
								type="text"
								size="small"
								@click="outStockHandle(scope.row)"
							>出库</el-button>
							<el-popconfirm
								v-if="scope.row.status === 1"
								title="确认取消这条药品订单？"
								@confirm="cancelHandle(scope.row.id)">
								<el-button style="margin-left: 10px" slot="reference" type="text" size="small">取消</el-button>
							</el-popconfirm>
							<el-button v-if="[2, 3, 5].indexOf(scope.row.status) > -1" type="text" size="small"
								@click="toRefund(scope.row)">退款</el-button>
						</template>
					</el-table-column>
				</el-table>
			</el-tab-pane>
		</el-tabs>
		<div style="float: right; margin-top: 20px;">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
		<!-- 出库 对话框 -->
		<el-dialog title="商品出库" :visible.sync="dialogFormVisible">
			<el-form :model="form" :rules="stockoutRules" ref="stockout" label-width="240px">
				<el-form-item label="物流名称" prop="logisticsNo">
					<el-select v-model="form.logisticsNo">
						<el-option v-for="item in expressMap" :key="item.num" :label="item.name" :value="item.num"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="物流单号" prop="logisticsOrderNo">
					<el-input v-model="form.logisticsOrderNo" autocomplete="on"></el-input>
				</el-form-item>
				<!-- <el-form-item label="发货地(例：四川省成都市)" prop="logisticsFrom">
					<el-input v-model="form.logisticsFrom" autocomplete="on"></el-input>
				</el-form-item>
				<el-form-item label="目的地(例：四川省成都市)" prop="logisticsTo">
					<el-input v-model="form.logisticsTo" autocomplete="on"></el-input>
				</el-form-item> -->
				<!-- <el-form-item label="物流运费">
					<el-input v-model="form.logisticsFee" autocomplete="on"></el-input>
				</el-form-item> -->
				<el-form-item label="物流付款方式">
					<el-input v-model="form.logisticsFeeType" autocomplete="on"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="clickToOut()">取 消</el-button>
				<el-button type="primary" @click="clickToOut(1)">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 退款 对话框 -->
		<el-dialog title="退款" :visible.sync="refundDialogVisible">
			<el-form :model="refundForm" :rules="refundRules" ref="refundF">
				<el-form-item label="退款金额" prop="refundFee">
					<div>￥{{refundForm.refundFee}}</div>
					<!-- <el-input-number v-model="refundForm.refundFee" :precision="2" :step="0.1" :min="0" :max="refundForm.maxFee" disabled></el-input-number> -->
				</el-form-item>
				<el-form-item label="备注" prop="remark">
					<el-input v-model="refundForm.remark" autocomplete="on"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="refundDialogVisible = false;">取 消</el-button>
				<el-button type="primary" @click="refund">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import expressMap from '../../utils/expressMap';

export default {
	data () {
		return {
			form: {},
			filters: {
				mobilePhone: '',
				status: '',
				timeRange: '',
				patientName: '',
				orderId: ''
			},
			expressMap,
			orderStatuss: [{
				label: '全部',
				value: '0'
			},
			{
				label: '待支付',
				value: '1'
			},
			{
				label: '待发货',
				value: '5'
			},
			{
				label: '待收货',
				value: '2'
			},
			{
				label: '已完成',
				value: '3'
			},
			{
				label: '已取消',
				value: '4'
			}
			],
			depts: [],
			pickerOptions: {
				shortcuts: [{
					text: '最近一周',
					onClick (picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick (picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick (picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			dataList: [],
			currentPage: 1,
			pageSize: 10,
			total: 0,
			dialogFormVisible: false,
			refundDialogVisible: false,
			refundForm: {
				id: '',
				refundFee: 0,
				maxFee: 0,
				remark: '',
				type: 1 // 写死的药品单
			},
			cancelVisible: false,
			refundRules: {
				refundFee: [
					{ required: true, message: '请输入退款金额', trigger: 'blur' }
				],
				remark: [
					{ required: true, message: '请输入退款备注', trigger: 'blur' }
				]
			},
			stockoutRules: {
				logisticsNo: [
					{ required: true, message: '请选择物流公司', trigger: 'blur' }
				],
				logisticsOrderNo: [
					{ required: true, message: '请输入物流单号', trigger: 'blur' }
				]
			}
		};
	},
	created () {
		this.search();
	},
	methods: {
		handleClickTab (tab, event) {
			this.currentPage = 1;
			this.getData();
		},
		search () {
			this.pageSize = 10;
			this.total = 0;
			this.currentPage = 1;
			this.getData();
		},
		getData () {
			let status = '';
			if (+this.filters.status) status = +this.filters.status;
			if (!this.filters.timeRange) this.filters.timeRange = ['', ''];
			this.$root.request('appointmentList', {
				...this.filters,
				startTime: this.filters.timeRange[0],
				endTime: this.filters.timeRange[1],
				status,
				page: this.currentPage,
				limit: this.pageSize
			}).then((data) => {
				if (data) {
					this.total = data.count;
					this.dataList = data.rows.map(r => {
						let rpArray = [];
						if (r.rp) rpArray = JSON.parse(r.rp);
						r.rpArray = [...rpArray];
						r.showFullPhone = false;
						r.totalFee = r.totalFee.toFixed(2);
						if (r.mobilePhone) {
							r.mobilePhoneHide = r.mobilePhone.substr(0, 3) + '****' + r
								.mobilePhone.substr(7);
						}
						switch (r.dosageForm) {
						case '1':
							r.dosageForm = '饮片';
							break;
						case '2':
							r.dosageForm = '颗粒';
							break;
						default:
							break;
						}
						return r;
					});
				}
			});
		},
		clickToShow (item) {
			item.showFullPhone = !item.showFullPhone;
		},
		detailHandle (id) {
			this.$root.go('/mnt/appointmentDetail', {
				id
			});
		},
		outStockHandle (item) {
			this.form.id = item.id;
			this.$root.request('appointmentDetail', {
				id: item.id
			}).then((data) => {
				if (data) {
					this.dialogFormVisible = true;
					this.form = {
						...item
					};
				}
			});
		},
		clickToOut (type) {
			if (type) {
				this.$refs.stockout.validate((valid) => {
					if (valid) {
						const logisticsName = this.expressMap.find(e => e.num === this.form.logisticsNo).name;
						this.$root.request('appointmentStockout', {
							id: this.form.id,
							logisticsOrderNo: this.form.logisticsOrderNo,
							logisticsNo: this.form.logisticsNo,
							logisticsName,
							logisticsFee: this.form.logisticsFee,
							logisticsFrom: '北京市',
							logisticsTo: '',
							logisticsFeeType: this.form.logisticsFeeType
						}).then((data) => {
							if (data && data.ok === 1) {
								this.$root.elMsg('操作成功');
								this.getData();
							}
						});
					} else {
						return false;
					}
				});
			}
			this.dialogFormVisible = false;
		},
		cancelHandle (id) {
			this.$root.request('appointmentCancel', {
				id
			}).then((data) => {
				if (data && data.ok === 1) {
					this.$root.elMsg('操作成功');
					this.getData();
				}
			});
		},
		handleSizeChange (size) {
			this.pageSize = size;
			this.currentPage = 1;
			this.getData();
		},
		handleCurrentChange (page) {
			this.currentPage = page;
			this.getData();
		},
		toRefund (item) {
			this.refundDialogVisible = true;
			this.refundForm.id = item.id;
			this.refundForm.maxFee = item.totalFee;
			this.refundForm.refundFee = item.totalFee;
		},
		refund () {
			this.$refs.refundF.validate((valid) => {
				if (valid) {
					this.$root.request('refundRecordAdd', this.refundForm).then((data) => {
						if (data && data.ok === 1) {
							this.$root.elMsg('操作成功');
							this.refundDialogVisible = false;
							this.refundForm = {
								id: '',
								refundFee: 0,
								remark: '',
								maxFee: 0,
								type: 1 // 写死的药品单
							};
							this.getData();
						}
					});
				} else {
					return false;
				}
			});
		}
	}
};
</script>

<style lang="less" scoped>
	.service-cell {
		display: flex;
		align-items: center;

		div {
			text-align: left;
			margin-left: 10px;
		}

		.doctor-avatar {
			width: 100px;
			height: 100px;
		}
	}

	.click-to-show {
		cursor: pointer;
		color: deepskyblue;
	}
</style>